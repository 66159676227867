#clientes .clientesMobile{
    margin-top: 2rem;
    padding-bottom: 2.5rem;
}

#clientes .clientesDesktop{
  display: none;
}

#clientes .clientesMobile .titulo{
    text-align: center;
    font-weight: 600;
    color: var(--blue);
}

#clientes .clientesMobile .titulo .strong{
    color: var(--celest);
    display: inline-block;
    font-weight: 900;
    padding: 0em 0.3em;
}

#clientes .clientesMobilecontainer {
    width: 100%;
    margin: auto;   
  }
  
  .rounded-image {
    width: 85%;
    height: auto;
    padding-top: 0rem;
    margin: 2rem auto 0.25rem;
  }

  @media (min-width: 768px){

    #clientes .clientesMobile{
      display: none;
  }
  
 #clientes .clientesDesktop{
    display: inline;
 }

 #clientes .clientesDesktop .titulo{
  text-align: center;
  font-weight: 600;
 }

 #clientes .clientesDesktop .titulo .strong{
  text-align: center;
  color: var(--blue);
  padding-left: 0.5rem;
  font-style: italic;
 }

 #clientes .clientesDesktop .container{
  display: flex;
  margin: 1.5rem auto 0;
  justify-content: center;
 }

 #clientes .clientesDesktop .container .imagenes{
  width: 33vw;
  margin: 2rem 0;
 }
 
  }

  @media (min-width: 1024px){
    #clientes .clientesDesktop .titulo{
      font-size: 2.75rem;
      text-align: center;
      margin-left: 0;
      color: var(--blue);
    }    

    
#clientes .clientesDesktop .titulo .strong{
  color: var(--celest);
  font-style: normal;
}

    #clientes .clientesDesktop .container .imagenes{
      width: 30vw;
      margin: 2rem 0;
     }
  }
