@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;600;700;900&display=swap');

.App {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto Condensed', sans-serif;
    overflow-x: hidden;
    scroll-behavior: smooth;
}


