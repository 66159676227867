.servicios{
    background-color: var(--blue);
    padding: 1.5rem 0 3rem;
    position: relative;
}
      
.servicios::before {   
    content: "";
    width: 100%;
    height: 170px;
    position: absolute;
    bottom: -0.3%;
    left: 0;
    background-size: auto;
    background-repeat: repeat no-repeat;
    background-position: 94vw bottom;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 127' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 21L33 18C82 12 133 14 200 28C267 42 333 63 400 67C467 70 533 56 600 38C667 21 733 0 800 0C867 0 933 21 1000 28C1067 35 1133 28 1167 24L1200 21V127H1167C1133 127 1067 127 1000 127C933 127 867 127 800 127C733 127 667 127 600 127C533 127 467 127 400 127C333 127 267 127 200 127C133 127 67 127 33 127H0V22.332Z' fill='%23ffffff'/></svg>")!important;  
}

.servicios .img{
    width: 20vw;
    display: flex;
    margin: 1rem auto;
    align-items: center;
}

.servicios .titulo{
    text-align: center;
    padding: 0rem 2.5rem;
    color: #fff;
    text-transform: uppercase;
    color: var(--celest);;
    font-weight: 900;
}

.servicios .titulo .strong{
    font-weight: 900;
    color: #fff;
    font-style: normal;
    padding-left: 0rem;
}

.servicios .card{
    display: flex;
    border-radius: 45px;
    padding: 22px 32px;
    text-align: center;
    margin: 2rem auto;
    width: 300px;
}

.servicios .card .titulo{
    font-size: 17px;
    font-weight: 700;
    color: #000;
    font-style: normal;
    padding: 0;
}

.servicios .texto{
    font-size: 14px;
}

.buttonContainer .button{
    background-color: var(--celest);
    border-radius: 20px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-style: italic;
    padding: 13px 19.5px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 0.5rem;
    transition: background-color 0.3s ease;
}

.buttonContainer .button:hover{
    background-color: var(--blue);
}

.buttonContainer a{
    text-decoration: none;
}

@media (min-width: 768px){

.servicios .Container-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 1rem 0 4rem;
}

.servicios .Container-card .card{
    width: 45vw;
    margin: 1rem 1rem;
    padding: 1rem;
}

.servicios .Container-card .card .img{
    width: 15vw;
}

.servicios .titulo{
    font-size: 1.6rem;
}
}
@media (min-width: 1024px){

    .servicios{
        padding-bottom: 6rem;
    }

    .servicios .titulo{
        text-align: center;
        font-size: 2.5rem;
        padding-top: 1rem;
        padding-left: 7rem;
    }
    
    .servicios .Container-card .card{
        width: 45vw;
        margin: 1rem 1rem;
        padding: 1rem;
    }

    .servicios .card .titulo{
        font-size: 20px;
        text-align: center;
        padding: 0.5rem 0;
        font-style: normal;
    }

    .servicios .card .texto{
        font-size: 18px;
    }
}

@media (min-width: 1440px){

    .servicios{
        padding-bottom: 8rem;
    }

    .servicios .Container-card .card{
        width: 22vw;
        margin: 1rem 1rem;
        padding: 1rem;
    }

    .servicios .Container-card .card .img{
        max-height: 13vw;
    }

    .servicios .titulo{
        text-align: center;
        margin:0;
        padding:2rem;
        padding-bottom: 0;
        font-size: 2.75rem;
    }

    .servicios .card .titulo{
        font-size: 22px;
        font-weight: 700;
        color: #000;
        font-style: normal;
    }


}