.contacto{
    margin-top: 0rem;
    padding: 2.5rem 0;
    background-color: #ffffffa1;
}

.contacto .titulo{
    display: inline-block;
    font-style: normal;
    font-weight: 900;
    padding: 0.2em 0.2em;
    padding-left: 5vw;
    line-height: 1.5em;
    color: var(--blue);
    font-size: 1.35rem;
}

.contacto .titulo .strong{
    color: var(--celest);
    font-weight: 900;
}

.contacto .Formulario{
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    width: 90vw;
}

.contacto .Formulario .Input{
    margin: 0.5rem 0;
    background-color: #fff;
}

.contacto .boton{
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    background-color: var(--celest);
    color: #fff;
    font-size: 1.2rem;
    width: 50vw;
}

.contacto .boton:hover{
    background-color: var(--celest);
    opacity: 0.99;
    color: #fff;
}

.contacto .boton:disabled{
    background-color: var(--celest);
    color: #fff;
    opacity: 0.8;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
    width: 40vw;
    background-color: var(--celest);
    color: #fff;
}

.contacto .button{
    background-color: var(--celest);
    border-radius: 20px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-style: italic;
    padding: 13px 19.5px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 1.25rem auto 0;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
    width: 40vw;
    background-color: var(--celest);
    color: #fff;
}

@media (min-width: 768px){

.contacto {
    width: 70vw;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.contacto .titulo{
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem;
}

.contacto .Formulario{
    width: 70vw;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
    width: 30vw;
}

}

@media (min-width: 1024px){
    .contacto .titulo{
        text-align: left;
        font-size: 2rem;
        margin-left: -1rem;
        margin-top: -1.5rem;
    }

    .contacto .button{
        padding: 1rem 3rem;
    }

    div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
        width: 15vw;
    }
}