body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    --violet: #BA8FDB;
    --blue: #5A66A3;
    --celest: #8CCDDB;
  }

@font-face {
  font-family: 'made sans';
  src: url('../public/fonts/made.otf') format('truetype');
  font-display: swap;
}


@font-face {
  font-family: 'made bold';
  src: url('../public/fonts/madeBold.otf') format('truetype');
  font-display: swap;
}