.footer {
    text-align: center;
    padding: 2rem;
    background-image: radial-gradient(#8CCDDB, #5A66A3)!important;
    font-size: 2rem;
}

.footer .titulo{
    font-size: 1.5rem;
    color: #fff;
    margin-top: 1.2rem;
}

.footer .titulo .brand{
    font-family: 'made bold';
    font-weight: 900;
}

.footer .texto{
    color: #fff;
    font-size: 12px;
    margin-top: 1.2rem;
}