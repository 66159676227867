.whatsapp {
    position: fixed;
    bottom: 16px;
    right: 16px;
  }
  
  .whatsapp button {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .icono {
    width: 50px; /* Ajusta según el tamaño deseado */
    height: auto;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 4px 30px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    opacity: 0.8;
  }
  
  .icono:hover {
    transform: scale(1.05);
    opacity: 1;
  }

  @media (min-width: 1024px) {

    .icono{
        width: 70px;
    }
  }