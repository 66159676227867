.header{
  width: 100%;
  background-image: radial-gradient(#8CCDDB, #5A66A3)!important;
  height: 450px;
  position: relative;
  margin-bottom: 5.2rem;
}

.header::before {   
  content: "";
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: -0.3%;
  left: 0;
  background-size: auto;
  background-repeat: repeat no-repeat;
  background-position: 94vw bottom;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 127' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 21L33 18C82 12 133 14 200 28C267 42 333 63 400 67C467 70 533 56 600 38C667 21 733 0 800 0C867 0 933 21 1000 28C1067 35 1133 28 1167 24L1200 21V127H1167C1133 127 1067 127 1000 127C933 127 867 127 800 127C733 127 667 127 600 127C533 127 467 127 400 127C333 127 267 127 200 127C133 127 67 127 33 127H0V22.332Z' fill='%23ffffff'/></svg>")!important;
}

.header .navBar .brand{
  color: #fff;
  padding: 2rem;
  font-weight: 700;
  cursor: pointer;
  font-family: 'made sans';
} 

.navbar {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 0rem;
}

.logo {
  width: 180px;
  padding: 1.5rem;
  margin-top: 0.35rem;
  cursor: pointer;
}

.menu-icon {
  display: block;
  font-size: 1.5em;
  font-size: 1.5em;
  color: #fff;
  font-weight: 700;
  margin: 1.5rem;
  cursor: pointer;
  border-radius: 10px;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  visibility: hidden;
  position: absolute;
  top: 170%;
  left: 50%;
  transform: translate(100%, -50%); 
  background-color: #fff;
  width: 90vw;
  flex-direction: column;
  z-index: 2;
  border: 1px solid #fff; 
  box-shadow: 0 0 10px #004678;
  transition: transform 0.75s ease-in; 
  overflow: hidden;
}

.menu.show {
  visibility: visible;
 border-radius: 15px;
  transform: translate(-50%, -50%); 
  margin-top: 2.25rem;
}

.menu-item {
  margin-right: 0;
  padding: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(4, 4, 4, 0.767);
  cursor: pointer;
  border-bottom: 0.5px solid rgb(215, 213, 213);
  padding: 13px 100%;
}

.spanish{
  width: 10px;
}

.menu-item:hover {
  font-weight: 700;
}

.menu-item:last-child{
  border-bottom: none;
  margin-bottom: -1.25rem;
}

.menu-item:hover {
  font-weight: 700;
}

@keyframes a-bars {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.header .bars {
  z-index: -1;
  margin-bottom: -9rem;
    background-size: cover;
    height: 60px;
    width: 400px;
  animation-direction: reverse;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-name: a-bars;
  animation-timing-function: linear;
  transform-origin: center; 
}

.header .line{
  display: none;
}


.header .titulo{
  text-align: center;
  padding: 0rem 1rem 1rem;
  color: white;
  font-size: 32px;
  font-weight: 300;
}

.header .strong{
  font-weight: 900;
}

.header .circle{
  width: 100px;
  z-index: -1;
  animation: rotate 400s linear infinite;
  display: flex;
  align-items: center;
  margin: 1rem 5rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header .circle {
  animation: rotate 4s linear infinite; 
  transform-origin: center; 
}

.header .galaxy{
    width: 350px;
    display: flex;
    align-items: center;
    margin: -11rem auto;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
   }
   
   .header .galaxy {
    animation: rotate 5000ms linear infinite;
   }


@media (min-width: 768px){
  .header{
    height: 470px;
  }

  .header .titulo{
    margin: 2rem 5rem;
    font-size: 2rem;
    padding-bottom: 0;
  }

  .header .container{
    display: flex;
  }

  .header .galaxy{
    width: 500px;
    margin: -10rem auto;
    margin-left: -8rem;
  }

  .header .circle{
    width: 140px;
    margin: 0rem;
  } 

  .header .bars {
    margin-bottom: -3rem;
      height: 90px;
      width: 400px;
      margin-top: -1rem;
  }

  .header .line{
    display: inline-block;
    z-index: -1;
    background-size: cover;
   padding-top: -6rem;
   /*  height: 60px;
    width: 800px;  */
    width: 400px;
  animation-direction: reverse;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-name: a-bars;
  animation-timing-function: linear;
  transform-origin: center; 
  }

  .header .titulo{
    margin-bottom: 0;
  }
  
}

@media (min-width: 1024px) {

  .header{
    padding-bottom: 5rem;
  }

  .menu-icon {
    display: none;
  }

  .header .navbar .logo{
    margin-left: 7rem;
    width: 200px;
  }

  .menu {
    visibility: visible;
    top: 50%;
    left: auto;
    right: 7vw;
    transform: translate(30%, -50%);
    background: none;
    border: none;
    display: flex;
    flex-direction: row;
    box-shadow: none;
  }

  .menu-item{
    color: #fff;
    font-size: 1.25rem;
    font-weight: 400;
    font-style: normal;
    border-bottom: none;
    overflow: auto;
    padding: 13px;
  }

  .menu-item:last-child{
    margin-bottom: 0rem;
  }

 .spanish{
    width: 1.5rem;
    margin-top: -0.2rem;
  }

  .header{
    height: 75%;
  }

  .header .container{
    display: flex;
    justify-content: space-between;
  }

  .header .container .titulo{
    font-size: 3rem;
    margin: 3vh 0 0;
    padding: 1rem 5rem 7vh;
    width: 50vw;
    text-align: left;
  }

  .header .container .galaxy{
    width: 500px;
    margin-top: -8rem;
    padding: 0;
  }

  .header .line{
    margin-top: -4rem;
  }
}

