.nosotros {
    padding: 4vh 10vw 4vh;
}

.nosotros b{
    font-weight: 600;
}

.nosotros .titulo{
    text-align: center;
    font-style: italic;
    color: var(--blue);
}

.nosotros .titulo .brand{
    color: #000;
    font-style: normal;
    font-family: 'made bold';
    opacity: 0.96;
}

.nosotros .texto{
    font-size: 16px;
    font-weight: 300;
    line-height: 19.5px;
    text-align: center;
    padding-top: 0.6rem;
    line-height: 1.65;
}

@media (min-width: 768px){

.nosotros .titulo{
    font-size: 2rem;
}

.nosotros .texto{
    font-size: 16px;
} 
    
}

@media (min-width: 1024px){

 .nosotros .titulo{
    text-align: left;
    font-size: 3rem;
    margin-top: -4rem;
 }   

 .nosotros .texto{
    font-size: 20px;
    text-align: left;
    padding: 1rem 2rem;
 }
}